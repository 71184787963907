<template>
    <div class="zl-list">
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>招生咨询</a-breadcrumb-item>
            <a-breadcrumb-item>企微群发</a-breadcrumb-item>
            <a-breadcrumb-item>编辑群发</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-box sendmessage">
            <div class="title flts-title">
                <h3></h3>
                <div>
                    <a-button size="small" style="margin-left:10px" @click="handleCancel">返回</a-button>
                </div>
            </div>
            <a-row>
                <a-col :span="16">
                    <a-form>
                        <a-form-item label="群发名称" :label-col="labelCol" :wrapper-col="wrapperCol">
                            <a-input v-model="name" placeholder="请输入群发名称" />
                        </a-form-item>
                        <a-form-item label="群发账号" :label-col="labelCol" :wrapper-col="wrapperCol">
                            <a-popover v-model="isCheckWarp" title="选择" trigger="click">
                                <div slot="content">
                                    <checkWarp />
                                </div>
                                <div style="display:flex;align-items:center;flex-wrap:wrap;min-height:39px">
                                    <a-tag style="margin:5px" v-for="(item,index) in check_name_list" :key="index">{{item.filter_name || item.department_name}}</a-tag>
                                    <a-icon style='fontSize:18px' type="plus-circle" />
                                </div>
                            </a-popover>
                        </a-form-item>
                        <a-form-item label="选择客户" :label-col="labelCol" :wrapper-col="wrapperCol">
                            <a-radio-group v-model="user" @change="handleRadio">
                                <a-radio :value="1">全部客户</a-radio>
                                <a-radio :value="2">筛选客户</a-radio>
                            </a-radio-group>
                            <div v-if="user === 2" class="sendmessage-textarea">
                                <a-form>
                                    <a-form-item label="性别" :label-col="labelCol" :wrapper-col="wrapperCol">
                                        <a-radio-group v-model="external_user_filter.gender" @change="count = -1">
                                            <a-radio value="">全部性别</a-radio>
                                            <a-radio :value="1">仅男性客户</a-radio>
                                            <a-radio :value="2">仅女性客户</a-radio>
                                            <a-radio :value="0">未知性别</a-radio>
                                        </a-radio-group>
                                    </a-form-item>
                                    <!-- <a-form-item label="所在群聊" :label-col="labelCol" :wrapper-col="wrapperCol">
                                       <a-select placeholder="请选择" style="width: 100%">
                                            <a-select-option value="jack">
                                                Jack
                                            </a-select-option>
                                            <a-select-option value="lucy">
                                                Lucy
                                            </a-select-option>
                                        </a-select>
                                    </a-form-item>
                                    <a-form-item label="所在群聊" :label-col="labelCol" :wrapper-col="wrapperCol">
                                       <a-range-picker style="width: 100%"/>
                                    </a-form-item> -->
                                    <a-form-item label="符合标签" :label-col="labelCol" :wrapper-col="wrapperCol">
                                       <a-tree-select
                                            style="width:100%"
                                            v-model="or_tag_list"
                                            :tree-data="tags"
                                            tree-checkable
                                            allowClear
                                            treeNodeFilterProp="title"
                                            :maxTagCount='2'
                                            :maxTagTextLength='5'
                                            :dropdownStyle="{zIndex:4200,maxHeight:'400px'}"
                                            :show-checked-strategy="SHOW_ALL"
                                            placeholder="请选择企业微信标签"
                                            @change="count = -1"
                                        />
                                    </a-form-item>
                                    <a-form-item label="排除标签" :label-col="labelCol" :wrapper-col="wrapperCol">
                                       <a-tree-select
                                            style="width:100%"
                                            v-model="not_or_tag_list"
                                            :tree-data="tags"
                                            tree-checkable
                                            allowClear
                                            treeNodeFilterProp="title"
                                            :maxTagCount='2'
                                            :maxTagTextLength='5'
                                            :dropdownStyle="{zIndex:4200,maxHeight:'400px'}"
                                            :show-checked-strategy="SHOW_ALL"
                                            placeholder="请选择企业微信标签"
                                            @change="count = -1"
                                        />
                                    </a-form-item>
                                </a-form>
                            </div>
                            <div>
                                <div @click="toCheckCount" class="sendmessage-remind sendmessage-count">
                                    <a-icon type="file-search" />
                                    <span style="margin-left:10px">查看该消息预计送达人数：</span>
                                    <span v-if="count == -1 && !loading">查看</span>
                                    <span v-if="count != -1">{{count}}</span>
                                    <a-spin  size="small" v-if="loading" />
                                </div>    
                            </div>
                        </a-form-item>
                        <a-form-item label="群发消息" :label-col="labelCol" :wrapper-col="wrapperCol">
                            <div class="sendmessage-textarea">
                                <a-textarea v-model="msg_data.text.content" placeholder="请输入回复内容" :rows="6" />
                            </div>
                            <div class="sendmessage-enclosure">
                                <div>
                                    <div v-for="(item,index) in attachments" :key="index" class="sendmessage-enclosure-list">
                                        <a-icon @click="delEnclosure(index)" type="minus-circle" />
                                        <span v-if="item.msgtype == 'image'">【图片】:</span>
                                        <span v-if="item.link && item.link.type == 'normal_link'">【链接】:</span>
                                        <span v-if="item.link && item.link.type == 'form_link'">【自定义表单】:</span>
                                        <span v-if="item.msgtype == 'image'">{{item.image.title}}</span>
                                        <span v-if="item.msgtype == 'link'">{{item.link.title}}</span>
                                        <a-icon @click="toEdit(item,index)" class="sendmessage-enclosure-edit" type="form" />
                                    </div>
                                </div>
                                <a-dropdown :trigger="['click']">
                                    <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                                        <a-icon type="plus-circle" />
                                        <span style="margin-left:10px">添加附件</span>
                                    </a>
                                    <a-menu slot="overlay">
                                        <a-menu-item @click="showImageModal()" key="1">
                                            图片
                                        </a-menu-item>
                                        <a-menu-item @click="showLinkModal()" key="2">
                                            链接
                                        </a-menu-item>
                                        <a-menu-item @click="showFormModal()" key="3">
                                            自定义表单
                                        </a-menu-item>
                                    </a-menu>
                                </a-dropdown>
                            </div>
                        </a-form-item>
                        <a-form-item label=" " :colon="false" :label-col="labelCol" :wrapper-col="wrapperCol">
                            <div class="sendmessage-remind">注意：客户每个月最多接收来自同一企业的管理员的 4 条群发消息，4条消息可在同一天发送</div>
                        </a-form-item>
                        <!-- <a-form-item label="群发时间" :label-col="labelCol" :wrapper-col="wrapperCol">
                            <a-radio-group v-model="send_type" @change="handleRadio">
                                <a-radio value="realtime">立即发送</a-radio>
                                <a-radio value="schedule">定时发送</a-radio>
                            </a-radio-group>
                        </a-form-item> -->
                        <a-form-item v-if="send_type == 'schedule'" label=" " :colon="false" :label-col="labelCol" :wrapper-col="wrapperCol">
                            <a-date-picker v-model="target_time" style="width: 240px" format="YYYY-MM-DD HH:mm" :show-time="{ format: 'HH:mm' }" placeholder="请选择"/>
                        </a-form-item>
                        <a-form-item label=" " :colon="false" :label-col="labelCol" :wrapper-col="wrapperCol">
                            <a-button :loading="confirmLoading" @click="toSend" type="primary">通知成员发送</a-button>
                        </a-form-item>
                    </a-form>
                </a-col>
                <a-col :span="8">
                    <div class="sendmessage-phone">
                        <img style="width:274px" src="@/assets/phone.png">
                        <div class="sendmessage-phone-content">
                            <div v-if="msg_data.text.content" class="sendmessage-phone-item">
                                <a-avatar shape="square" :size="26" icon="user" />
                                <div class="sendmessage-phone-item-text">
                                    {{msg_data.text.content}}
                                </div>
                            </div>
                            <div v-for="(item,index) in attachments" :key="index" class="sendmessage-phone-item">
                                <a-avatar shape="square" :size="26" icon="user" />
                                <div v-if="item.msgtype == 'image'" class="sendmessage-phone-item-img">
                                    <img :src="item.image.pic_url">
                                </div>
                                <div v-else class="sendmessage-phone-item-link">
                                    <div class="sendmessage-phone-item-linkTitle">{{item.link.title}}</div>
                                    <div class="sendmessage-phone-item-linkWarp">
                                        <div class="sendmessage-phone-item-linkDesc">{{item.link.desc}}</div>
                                        <img class="sendmessage-phone-item-linkImg" :src="item.link.picurl" >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </a-col>
            </a-row>
        </div>
        <imageModal :item="modalData" v-if="isImageModal" />
        <linkModal :item="modalData" v-if="isLinkModal" />
        <formModal :item="modalData" v-if="isFormModal" />
    </div>
</template>

<script>
    import moment from 'moment'
    import checkWarp from '../CheckWarp'
    import imageModal from '../imageModal'
    import linkModal from '../linkModal'
    import formModal from '../formModal'
    import { TreeSelect } from 'ant-design-vue'
    const SHOW_ALL = TreeSelect.SHOW_ALL
    export default {
        name:'editModal',
        data() {
            return {
                name:'',
                SHOW_ALL,
                labelCol: { span: 4 },
                wrapperCol: { span: 18 },
                loading:false,
                confirmLoading: false,
                isCheckWarp: false,
                isImageModal: false,
                isLinkModal: false,
                isFormModal: false,
                check_name_list:[],
                user:1,
                tags:[],
                msg_data:{
                    text:{content:''}
                },
                external_user_filter:{gender:''},
                send_type:'realtime',
                target_time:'',
                attachments:[],
                editIndex:-1,
                modalData:{},
                or_tag_list:[],
                not_or_tag_list:[],
                count:-1

            }
        },
        provide() {
            return {
                parent: this
            }
        },
        components: {
            checkWarp,
            imageModal,
            linkModal,
            formModal,
        },
        created () {
            this.$ls.remove('check_name_list')
            this.$ls.remove('send_employee_ids')
            this.$ls.remove('send_employee_list')
            this.getTag()
        },
        methods: {
            async getTag(){
                let res = await this.$store.dispatch('wxworkMsgTagAction', {})
                this.tags = res.data
            },
            hideCheckWarp(val){
                if(val){
                    this.count = -1
                    this.check_name_list = JSON.parse(this.$ls.get('check_name_list'))
                }
                this.isCheckWarp = false
            },
            handleRadio(e){
                this.count = -1
                if(e.target.value === 2 || e.target.value === 1){
                    this.external_user_filter = {gender:''}
                }
                if(e.target.value === 'realtime'){
                    this.target_time = ''
                }
            },
            showImageModal(item){
                this.modalData = item
                this.isImageModal = true
            },
            hideImageModal(num,val){
                if(num === 1){
                    this.attachments[this.editIndex] = val
                }else if(num === 0){
                    this.attachments.push(val)
                }
                this.isImageModal = false
            },
            showLinkModal(item){
                this.modalData = item
                this.isLinkModal = true
            },
            hideLinkModal(num,val){
                if(num === 1){
                    this.attachments[this.editIndex] = val
                }else if(num === 0){
                    this.attachments.push(val)
                }
                this.isLinkModal = false
            },
            showFormModal(item){
                this.modalData = item
                this.isFormModal = true
            },
            hideFormModal(num,val){
                if(num === 1){
                    this.attachments[this.editIndex] = val
                }else if(num === 0){
                    this.attachments.push(val)
                }
                this.isFormModal = false
            },
            delEnclosure(index){
                this.attachments.splice(index,1)
            },
            toEdit(item,index){
                this.editIndex = index
                if(item.msgtype == 'image'){
                    this.showImageModal(item)
                }else if(item.link && item.link.type == 'normal_link'){
                    this.showLinkModal(item)
                }else{
                    this.showFormModal(item)
                }
            },
            async toSend(){
                if(!this.name){
                    this.$message.warning('请填写群发名称！！')
                    return false
                }
                if(!this.msg_data.text.content){
                    this.$message.warning('请填写群发消息！！')
                    return false
                }
                this.confirmLoading = true
                let mission_info = {
                    name:this.name,
                    send_type:this.send_type,
                    msg_data:this.msg_data,
                    external_user_filter:this.external_user_filter
                }
                if(this.check_name_list.length>0){
                    mission_info.external_user_filter.staff_ext_id_list = this.check_name_list.map(item=>{return item.employee_id})
                }
                if(this.or_tag_list.length>0 && this.not_or_tag_list.length>0){
                    mission_info.external_user_filter['tag_condition'] = {or_tag_list:this.or_tag_list,not_or_tag_list:this.not_or_tag_list}
                }else if(this.or_tag_list.length>0 && this.not_or_tag_list.length == 0){
                    mission_info.external_user_filter['tag_condition'] = {or_tag_list:this.or_tag_list}
                }else if(this.or_tag_list.length == 0 && this.not_or_tag_list.length > 0){
                    mission_info.external_user_filter['tag_condition'] = {not_or_tag_list:this.not_or_tag_list}
                }
                if(this.attachments.length>0){
                    mission_info.msg_data.attachments = this.attachments
                }
                if(this.target_time){
                    mission_info.target_time = moment(this.target_time).format('YYYY-MM-DD HH:mm')
                }
                await this.$store.dispatch('missionAddSingleAction',{data:mission_info})
                .then(res=>{
                    this.confirmLoading = false
                    this.$router.go(-1)
                })

            },
            handleCancel() {
                this.$router.go(-1)
            },
            toCheckCount(){
                this.loading = true
                let mission_info = {
                    external_user_filter:this.external_user_filter
                }
                if(this.check_name_list.length>0){
                    mission_info.external_user_filter.staff_ext_id_list = this.check_name_list.map(item=>{return item.employee_id})
                }
                if(this.or_tag_list.length>0 && this.not_or_tag_list.length>0){
                    mission_info.external_user_filter['tag_condition'] = {or_tag_list:this.or_tag_list,not_or_tag_list:this.not_or_tag_list}
                }else if(this.or_tag_list.length>0 && this.not_or_tag_list.length == 0){
                    mission_info.external_user_filter['tag_condition'] = {or_tag_list:this.or_tag_list}
                }else if(this.or_tag_list.length == 0 && this.not_or_tag_list.length > 0){
                    mission_info.external_user_filter['tag_condition'] = {not_or_tag_list:this.not_or_tag_list}
                }
                this.$store.dispatch('missionSingleCountAction',{data:mission_info})
                .then(res=>{
                    this.count = res.data.count
                    this.loading = false
                })
            }
        },
    }
</script>

<style lang="scss" scoped>
    .sendmessage{
        &-textarea{
            background: #fbfbfb;
            border: 1px solid #e8e8e8;
            border-radius: 2px;
            &-name{
                border-bottom: 1px dashed #e8e8e8;
                padding-left: 10px;
            }
            textarea{
                resize: none;
                border: none;
                background: #fbfbfb;
            }
            textarea:focus{
                box-shadow: none;
            }
        }
        &-radio{
            padding-left:10px
        }
        &-enclosure{
            background: #fbfbfb;
            border: 1px solid #e8e8e8;
            border-top: none;
            padding: 0 15px;
            &-list{
                position: relative;
                display: flex;
                align-items: center;
            }
            &-edit{
                position: absolute;
                right: 0;
            }
        }
        &-count{
            padding-right: 15px;
            margin-top: 15px;
            cursor: pointer;
            display: inline-block;
        }
        &-remind{
            background: #f6ffed;
            border-radius: 3px;
            border: 1px solid #b7eb8f;
            padding-left: 15px;
        }
        &-phone{
            display: inline-block;
            position: relative;
            &-content{
                width: 224px;
                height: 350px;
                position: absolute;
                top: 90px;
                left: 20px;
                overflow-y: scroll;
                padding: 20px 5px 0 5px;
            }
            &-item{
                display: flex;
                margin-bottom: 10px;
                &-text{
                    width: 160px;
                    margin-left: 10px;
                    background: #fff;
                    padding: 8px;
                    border-radius: 2px;
                    word-break: break-all;
                    white-space: pre-wrap;
                }
                &-img{
                    max-width: 160px;
                    margin-left: 10px;
                    background: #fff;
                    padding: 8px;
                    border-radius: 2px;
                    img{
                        max-width: 144px;
                    }
                }
                &-link{
                    width: 160px;
                    margin-left: 10px;
                    background: #fff;
                    padding: 8px;
                    border-radius: 2px;
                }
                &-linkWarp{
                    display: flex;
                }
                &-linkTitle{
                    width: 140px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    word-break: break-all;
                }
                &-linkDesc{
                    flex: 1;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-box-flex: 1;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    height: 35px;
                    font-size: 12px;
                    color: rgba(0,0,0,.64);
                }
                &-linkImg{
                    width: 40px;
                    height: 40px;
                }
            }
        }
    }
</style>